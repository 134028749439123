import React, { useState, useContext } from "react"
import { FirebaseContext, useAuth } from "../../firebase"
import { useMicroCopyFi } from "../../hooks/microCopy"
import GetMicroCopy from "../../shared/getMicroCopy"
import { navigate } from "gatsby"

import Box from "@material-ui/core/Box"
import * as styles from "./form.module.scss"
import Spinner from "../UI/Spinner/Spinner"
import { async } from "@firebase/util"

const cors = require('cors')({ origin: true });

const CodeForm = ({ location }) => {
  const microCopyTexts = useMicroCopyFi
  // UI texts from Contentful
  const labelErrorWrongCode = GetMicroCopy(microCopyTexts, "lomakeVääräKoodi")
  const labelRegisterCodeTitle = GetMicroCopy(microCopyTexts, "koodiOtsikko")
  const labelRegisterCodeText = GetMicroCopy(microCopyTexts, "koodiTeksti")
  const labelCode = GetMicroCopy(microCopyTexts, "lomakeKoodi")
  const labelSubmit = GetMicroCopy(microCopyTexts, "lomakeLähetä")
  const labelRegisterNewCode = GetMicroCopy(
    microCopyTexts,
    "lomakeRekisteröidyUusiKoodi"
  )
  // End UI texts

  const { user } = useAuth()

  const [userCodeFormValues, setUserCodeFormValues] = useState({
    email: "",
    code: "",
  })


  const [used, setUsed] = useState(false)
  const [phase, setPhase] = useState(0);

  const [loading, setLoading] = useState(false)

  const { firebase } = useContext(FirebaseContext)
  const [errorMessage, setErrorMessage] = useState("")

  React.useEffect(() => {
    const authorise = async () => {
        const response = await firebase.getUser({email: user.email});
          if (response.data.contentAccess === 'Authorised') {
            navigate('/loviisa-2023/kohteet')
          }
    }
    if (firebase && user) {
      authorise();
    }

}, [firebase, user]);

  function retryNewCode() {
    setUsed(false)
    setErrorMessage("")
    userCodeFormValues.code = ""
    setPhase(0)
  }

  async function handleSubmitUserCode(e) {
    e.preventDefault()
    setLoading(true)
    const access = await fetch(`https://us-central1-virtuaaliset-asuntomessu-dad9b.cloudfunctions.net/handleMobileCode?code=${userCodeFormValues.code}&email=${user.email}`)
    const data = await access.text();

    if (data === "Success!" || data === 'Code is now reserved!') {
      firebase.updateAccessPermissions({code: userCodeFormValues.code, email: user.email})
      navigate('/loviisa-2023/kohteet')
    }
    else setErrorMessage(labelErrorWrongCode)
    setLoading(false)
  }


  function handelInputChangeUserCode(e) {
    e.persist()
    setErrorMessage("")
    setUserCodeFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))
  }

  return (
    <Box
      component="section"
      className={styles.form}
      style={{ padding: "0 20px", maxWidth: "1240px", margin: "85px auto" }}
    >
      <Box component="div">
        {phase === 0 && !loading && (
          <header>
            <h1>{labelRegisterCodeTitle}</h1>
            <p>{labelRegisterCodeText}</p>
          </header>
        )}

        <Box component="div" className={styles.formContainer}>
          {loading && <Spinner />}

          {phase === 0 && !loading && user && (
            <form onSubmit={handleSubmitUserCode}>
              <div className={styles.formRow}>
                {used && !loading && (
                  <p className={styles.alignCenter}>
                    <span onClick={retryNewCode} className={styles.link}>
                      {labelRegisterNewCode}
                    </span>
                  </p>
                )}
                <input
                  value={userCodeFormValues.email = user.email}
                  name="email"
                  type="hidden"
                ></input>
                <label>
                  {labelCode} <span>*</span>
                </label>
                <input
                  value={userCodeFormValues.code}
                  name="code"
                  onChange={handelInputChangeUserCode}
                  type="text"
                  required
                ></input>
              </div>

              <div className={styles.formRow}>
                {!!errorMessage && (
                  <p className={styles.errorMessage}>{errorMessage}</p>
                )}
              </div>

              <button type="submit">{labelSubmit}</button>
            </form>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default CodeForm
